<template>
  <h2>Voici votre score final</h2>
  <hr>
  <div id="recap_final_div">
    <div id="first_recap_score" :class="css_evaluation(partAR1)">Premier récapitulatif<span class="percent">{{
        partAR1
      }}</span></div>
    <div id="second_recap_score" :class="css_evaluation(partAR2)">Deuxième récapitulatif<span class="percent">{{
        partAR2
      }}</span></div>
    <div id="third_recap_score" :class="css_evaluation(partAR3)">Troisième récapitulatif<span class="percent">{{
        partAR3
      }}</span></div>
    <div id="action_plan_score" :class="css_evaluation(partB02)">Plan<br>d'action<span class="percent">{{
        partB02
      }}</span></div>
    <div id="quizz_score" :class="css_evaluation(partC1 / 1.8)">Questionnaire<br>final<span class="value">{{
        partC1
      }}/180</span></div>
    <div id="total_score" :class="css_evaluation(total / 5.8)">Score<br>total<span class="value">{{ total }}/580</span></div>
  </div>
  <p class="expert_on_the_left">
    Cliquez sur suivant pour continuer.
  </p>

  <hr>
  <button class="big" @click="nextSubPart">Suivant</button>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "subpartC02",
  emits: ['nextsubpart'],
  data() {
    return {
      partAR1: 0,
      partAR2: 0,
      partAR3: 0,
      partB02: 0,
      partC1: 0
    }
  },
  computed: {
    total() {
      return this.partAR1 + this.partAR2 + this.partAR3 + this.partB02 + this.partC1;
    },
    ...mapGetters([
      'getSubpart',
    ])
  },
  methods: {
    nextSubPart() {
      this.$emit('nextsubpart');
    },
    css_evaluation: function (v) {
      return v > 80 ? 'good' : (v > 50 ? ' fair' : 'bad');
    }
  },
  mounted() {
    this.partAR1 = 1 * this.getSubpart('partA.partAR1.score');
    this.partAR2 = 1 * this.getSubpart('partA.partAR2.score');
    this.partAR3 = 1 * this.getSubpart('partA.partAR3.score');
    this.partB02 = 1 * this.getSubpart('partB.partB02.action_result');
    this.partC1 = 10 * this.getSubpart('partC.partC01.score');
  },
}
</script>

<style scoped>
#recap_final_div {
  background-image: url('/img/recap_final.png');
  background-repeat: no-repeat;
  position: relative;
  padding-bottom: 40%;
}

#first_recap_score, #second_recap_score, #third_recap_score, #action_plan_score, #quizz_score, #total_score {
  width: 140px;
  height: 110px;
  padding-top: 30px;
  position: absolute;
  text-align: center;
  border-radius: 50%;
  background: #f5f4d3;
  overflow: hidden;
}

.percent, .value {
  display: block;
  background: #c4c4c4;
  position: absolute;
  top: 50%;
  width: 100%;
  line-height: 45px;
  height: 150px;
  color: #f5f4d3;
  font-weight: bold;
}

.percent {
  font-size: 180%;
}

.value {
  font-size: 150%;
}

.percent:after {
  content: '%';
}

#first_recap_score {
  left: 20px;
  top: 20px;
}

#second_recap_score {
  left: 170px;
  top: 20px;
}

#third_recap_score {
  left: 320px;
  top: 20px;
}

#action_plan_score {
  left: 90px;
  top: 160px;
}

#quizz_score {
  left: 250px;
  top: 160px;
}

#total_score {
  left: 700px;
  top: 70px;
  width: 200px;
  height: 175px;
  padding-top: 25px;
  font-size: 180%;
}

#total_score .value {
  font-size: 130%;
  padding-top: 10px;
}

.bad .percent, .bad .value {
  background: #fa7c56;
}

.fair .percent, .fair .value {
  background: #ffc428;
}

.good .percent, .good .value {
  background: #a1c156;
}

</style>