<template>
  <h2 class="white_title">Quiz final écoute</h2>

  <hr/>

  <p class="expert_on_the_left">Sélectionner les affirmations que vous jugez pertinentes</p>

  <hr/>

  <div class="expat_form_div quizC01" v-bind:class="{ correction: correctionState }">
    <p v-if="correctionState">
      Votre score est de {{ score }}/18.<br/>
      Vous pouvez regarder la correction ci-dessous, puis cliquez sur suivant pour obtenir votre score final.
    </p>
    <p v-if="correctionState">
      Dans cette correction, les affirmations fausses sont <span style="text-decoration: line-through;">barrées</span>.
      Si vous avez répondu correctement, la case à cocher est <span style="color: green">verte</span>, sinon elle est
      <span style="color:red">rouge</span>.
    </p>
    <FormKit
        type="form"
        :submit-label="correctionState ? 'Vos réponses ont été enregistrées' : 'Enregistrer vos réponses'"
        @submit="sendForm"
        :disabled="correctionState"
    >
      <FormKit
          v-model="fA"
          name="fA"
          type="checkbox"
          label="L’écoute du patient doit être réalisée de préférence"
          :options="{fA1: 'Dans sa chambre d’hôpital', fA2: 'Hors des services de soins'}"
          help="Plusieurs réponses possibles"
      />
      <FormKit
          v-model="fB"
          name="fB"
          type="checkbox"
          label="L’écoute du patient a pour objectif"
          :options="{fB1: 'L’amélioration de son expérience', fB2: 'L’amélioration de l’expérience des futurs patients'}"
          help="Plusieurs réponses possibles"
      />
      <FormKit
          v-model="fC"
          name="fC"
          type="checkbox"
          label="Lors de l’accueil du patient pour l’entretien"
          :options="{fC1: 'Garder sa tenue professionnelle', fC2: 'Se présenter en expliquant en détails sa fonction'}"
          help="Plusieurs réponses possibles"
      />
      <FormKit
          v-model="fD"
          name="fD"
          type="checkbox"
          label="Avant de débuter l’entretien"
          :options="{fD1: 'Rappeler l’objectif de l’entretien', fD2: 'Préciser le rôle de chacun des membres du binôme'}"
          help="Plusieurs réponses possibles"
      />
      <FormKit
          v-model="fE"
          name="fE"
          type="checkbox"
          label="Il faut débuter l’entretien"
          :options="{fE1: 'Par une question précise', fE2: 'En ne posant pas de question'}"
          help="Plusieurs réponses possibles"
      />
      <FormKit
          v-model="fF"
          name="fF"
          type="checkbox"
          label="La reformulation a pour objet"
          :options="{
          fF1: 'De vérifier que l’on a bien compris ce que disait le patient',
          fF2: 'De permettre au patient de comprendre qu’il a été entendu',
          fF3: 'De pouvoir relancer l’entretien sur des points du récit que l’on voudrait approfondir'
        }"
          help="Plusieurs réponses possibles"
      />
      <FormKit
          v-model="fG"
          name="fG"
          type="checkbox"
          label="Il est préférable de clore l’entretien"
          :options="{fG1: 'En remerciant le patient pour sa contribution', fG2: 'En lui disant qu’on lui fera un retour'}"
          help="Plusieurs réponses possibles"
      />
      <FormKit
          v-model="fH"
          name="fH"
          type="checkbox"
          label="Il est préférable de choisir les actions"
          :options="{
          fH1: 'Les plus faciles à réaliser',
          fH2: 'Qui consomment le moins de ressources',
          fH3: 'Qui apportent la plus forte plus-value'
        }"
          help="Plusieurs réponses possibles"
      />
    </FormKit>

  </div>
  <hr>
  <p class="expert_on_the_left" v-if="!correctionState">
    Pour enregistrer vos réponses, cliquez sur enregistrer.
  </p>

  <p class="expert_on_the_left" v-if="correctionState">
    Cliquez sur suivant pour obtenir votre score final.
  </p>

  <hr>
  <p v-if="correctionState">
    <button class="big" @click="nextSubPart">Suivant</button>
  </p>
</template>

<script>
import {nextTick} from 'vue';

export default {
  name: "subpartC01",
  components: {},
  emits: ['nextsubpart'],
  data() {
    return {
      value: null,
      fA: null,
      fB: null,
      fC: null,
      fD: null,
      fE: null,
      fF: null,
      fG: null,
      fH: null,
      correctionState: false,
      score: 0,
    }
  },
  methods: {
    async sendForm(formData/*, node,a, b*/) {
      const self = this;
      console.log('sendForm', self);
      const code = localStorage.getItem('expat.code');
      const {data} = await this.$http.post(
          process.env.VUE_APP_BACKEND,
          {formData: JSON.stringify(formData), code: code},
          {headers: {'X-Requested-With': 'XMLHttpRequest', 'X-Requested-Type': 'json', 'X-Remote-Call': 'SendForm'}}
      );

      // console.log('Send', data);
      if (data.status === 'OK') {
        /*let styleElement = document.createElement('style');
        styleElement.innerText = data.styleTag;
        document.head.append(styleElement);*/

        data.selectors.forEach(el => {
          // console.log(document.querySelector(el));
          let elem = document.querySelector(el);
          console.log('EL', el, elem);
          if (elem) {
            console.log('correct');
            elem.classList.add('correct');
          }
        });

        self.score = data.score;
        console.log('Part3 save');
        this.$store.dispatch('saveProgression', {path: 'partC.partC01.score', data: data.score});
      }
      await nextTick(() => {
        setTimeout(function () {
          self.correctionState = true;
        }, 500);
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      });
    },
    nextSubPart() {
      this.$emit('nextsubpart');
    },
  },
  /*mounted() {
    console.log('C01 mounted', document.querySelector("#input_1 .formkit-option:nth-child(1)"));
  }*/
}
</script>

<style>
.expat_form_div {
  max-width: 100%;
  width: 700px;
  margin: auto;
  text-align: center;
}

/*.formkit-inner INPUT {*/
/*  width: 80%;*/
/*  margin: auto;*/
/*}*/

/*.formkit-inner::before {*/
/*  content: "👍";*/
/*}*/

/*.formkit-inner::after {*/
/*  content: "👎";*/
/*}*/

:root {
  --fk-font-family: Montserrat, Arial, sans-serif;
  --fk-max-width-input: 100%;
  --fk-padding-legend: 5px;
}

.quizC01 UL {
  text-align: left;
}

[data-type="checkbox"] [data-disabled], [data-type="radio"] [data-disabled] {
  opacity: 0.6;
}

.formkit-outer[data-disabled] .formkit-fieldset {
  background-color: #f7f7f7;
}


.correction #input_1 .formkit-option:nth-child(1) .formkit-label,
.correction #input_2 .formkit-option:nth-child(1) .formkit-label,
.correction #input_3 .formkit-option:nth-child(1) .formkit-label,
.correction #input_3 .formkit-option:nth-child(2) .formkit-label,
.correction #input_5 .formkit-option:nth-child(1) .formkit-label,
.correction #input_8 .formkit-option:nth-child(3) .formkit-label {
  text-decoration: line-through;
}

.correct {
  /*outline: 1px solid green;*/
  position: relative;
}

.correct:before {
  content:'+1';
  position: absolute;
  left: -30px;
  top: 5px;
  font-size: 80%;
}

</style>