<template>

  <FormKit
      v-model="value"
      type="radio"
      :name="name"
      :label="label"
      :options="['1', '2', '3', '4', '5', '6']"
      outer-class="qeval"
  />
</template>

<script>
export default {
  name: "qeval",
  props: [
      'label',
      'name'
  ],
  data() {
    return {
      value: null,
    }
  },
}
</script>

<style>
.qeval LI.formkit-option {
  display: inline-block;
  padding-top: 30px;
}

.qeval .formkit-options::before {
  content: 'Pas du tout d’accord';
  display: inline-block;
  margin-right: 20px;
  font-size: 80%;
}

.qeval .formkit-options::after {
  content: 'Tout à fait d’accord';
  display: inline-block;
  margin-left: 20px;
  font-size: 80%;
}

.qeval .formkit-wrapper {
  position: relative;
  width: 50px;
  text-align: center;
}

.qeval .formkit-label {
  position: absolute;
  bottom: 30px;
  width: 100%;
  text-align: center;
}

.qeval .formkit-inner {
  margin: auto;
  text-align: center;
}
</style>