<template>
  <subpartC01 v-if="subpart_ === 'partC01'" :key="subpart_" @nextsubpart="nextsubpart"/>
  <subpartC02 v-if="subpart_ === 'partC02'" :key="subpart_" @nextsubpart="nextsubpart"/>
  <subpartC03 v-if="subpart_ === 'partC03'" :key="subpart_" @nextsubpart="nextsubpart"/>
</template>

<script>

import subpartC01 from '../components/subpartC01.vue';
import subpartC02 from '../components/subpartC02.vue';
import subpartC03 from '../components/subpartC03.vue';

export default {
  name: "partC",
  props: ['subpart'],
  components: {
    subpartC01,
    subpartC02,
    subpartC03,
  },
  data() {
    return {
      subpart_: this.subpart,
    }
  },
  methods: {
    nextsubpart() {
      this.veiled = false;
      console.log('sending nextsubpart from partC');
      this.$emit('nextsubpart', '/partC/' + this.subpart_);
    },
  },
  created() {
    this.$watch(
        () => this.$route,
        () => {
          this.subpart_ = this.$route.params.subpart;
        },
        {immediate: true}
    );
  },
  emits: ['nextsubpart'],
  computed: {
    progression() {
      return this.$store.state.parts;
    }
  },
}
</script>

<style>



</style>