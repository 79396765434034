<template>
  <h1>Questionnaire de satisfaction</h1>

  <div class="expat_form_div quizC03">
    <FormKit
        type="form"
        @submit="sendForm"
    >
      <qeval name="fA"
             label="Après avoir terminé ce jeu d’initiation, j’ai le sentiment de mieux cerner les enjeux de la prise en compte de l’expérience patient."/>
      <qeval name="fB" label="La démo de la première partie m’a été utile."/>
      <qeval name="fC" label="La durée des différentes séquences du récit est bien adaptée."/>
      <qeval name="fD" label="Il est facile d’utiliser les mots clés proposés."/>
      <qeval name="fE" label="C’est utile de comparer ses résultats à ceux validés par le patient écouté"/>
      <qeval name="fF" label="Les messages accompagnant les résultats sont intéressants"/>
      <qeval name="fG" label="L’ergonomie du jeu est bien pensée"/>
      <qeval name="fH" label="Le parti pris graphique renforce l’agrément du jeu"/>
      <qeval name="fI"
             label="Il est nécessaire d’écouter toutes les séquences pour traiter correctement la partie « action »"/>
      <qeval name="fJ" label="La démo de la deuxième partie m’a été utile"/>
      <qeval name="fK"
             label="La synthèse des entretiens est claire et utile pour juger de la pertinence des actions proposées"/>
      <qeval name="fL"
             label="Les informations contenues dans les fiches action sont suffisantes pour classer les actions par ordre de priorité "/>
      <qeval name="fM"
             label="Je recommanderai ce jeu à tous ceux qui veulent en savoir plus sur l’expérience patient "/>
    </FormKit>
  </div>

</template>

<script>
import Qeval from "@/components/qeval";
// import {nextTick} from "vue/dist/vue";

export default {
  name: "subpartC03",
  components: {Qeval},
  methods: {
    async sendForm(formData/*, node,a, b*/) {
      const self = this;
      console.log('sendForm', self);
      const code = localStorage.getItem('expat.code');
      const {data} = await this.$http.post(
          process.env.VUE_APP_BACKEND,
          {formData: JSON.stringify(formData), code: code},
          {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'X-Requested-Type': 'json',
              'X-Remote-Call': 'SendQuestionnaire'
            }
          }
      );

      console.log('Send', data);
      /*if (data.status === 'OK') {
        let styleElement = document.createElement('style');
        styleElement.innerText = data.styleTag;
        document.head.append(styleElement);
        self.score = data.score;
        console.log('Part3 save');
        this.$store.dispatch('saveProgression', {path: 'partC.partC01.score', data: data.score});
      }
      await nextTick(() => {
        self.correctionState = true;
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      });*/
    },
  },
}
</script>

<style scoped>

</style>